@charset "utf-8";

@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

$family-sans-serif: "Fira Code", sans-serif;
$body-background-color: hsla(214, 18%, 72%, 0.24);
$link: hsl(217, 92%, 43%);
$danger: hsl(360, 100%, 40%);
$warning: #d4ab38;
$success: hsl(141, 53%, 31%);
$footer-padding: 1.2rem 1.2rem 1.2rem;
$footer-background-color: $body-background-color;

@import "../node_modules/bulma-switch-control/bulma-switch-control";
@import "../node_modules/bulma/bulma.sass";
@import "~@creativebulma/bulma-divider";

.pointer {
  cursor: pointer;
}

.capitalized {
  text-transform: capitalize;
}

.is-underlined {
  text-decoration: underline;
}

.spinning {
  transition-duration: 0.8s;
  transition-property: transform;
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

.button.is-multiline {
  min-height: 2.25em;
  white-space: unset;
  height: auto;
  flex-direction: column;
}

.content-img {
  img {
    display: block;
    margin: 0 auto;
    max-width: 70%;
  }
}

.has-text-violet {
  color: #722c9c !important;
}

html,
body {
  min-height: 100vh;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#wrapper {
  flex: 1;
}
